import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import "./Landing.css";
import linkedin from "../../Assets/Image/linkedin.png";
import dribble from "../../Assets/Image/dribble.png";
import twitter from "../../Assets/Image/twitter.png";
import behance from "../../Assets/Image/behance.png";
import discord from "../../Assets/Image/discord.png";
import { getInfo } from "../../firebase/FirebaseFetch";
import Loading from "../Loading/Loading";
function Landing(props) {
  const [data, setData] = useState();
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    getData()
      .then((res) => setData(res))
      .catch((err) => console.log(err));
  }, []);
  const getData = async () => {
    const response = await getInfo().catch((err) => console.log(err));
    setIsloading(false);
    return response;
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <Container className="landing">
        <Row className="justify-content-center align-items-center">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="profileImage d-flex j justify-content-center"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              transition={{
                delay: 0,
                duration: 0.5,
              }}
            >
              <div
                className="liquid_shape"
                style={{
                  backgroundImage: `url(${data.profile_image.stringValue})`,
                }}
              ></div>
            </motion.div>
          </Col>
          <Col
            className="information d-flex justify-content-start"
            sm={12}
            md={6}
            lg={6}
          >
            <motion.div
              initial={{ x: "-10vw" }}
              animate={{ x: 0 }}
              transition={{ duration: 1, type: "tween" }}
            >
              <h2 className="title">{data.name.stringValue}</h2>
              <p className="description">{data.home_desc.stringValue}</p>
              <div className="socialLinks">
                <a className="socialLink" href={data.linkedin.stringValue}>
                  <img src={linkedin} alt="linkedin" />
                </a>

                <a className="socialLink" href={data.dribbble.stringValue}>
                  <img src={dribble} alt="dribble" />
                </a>

                <a className="socialLink" href={data.twitter.stringValue}>
                  <img src={twitter} alt="twitter" />
                </a>

                <a className="socialLink" href={data.behance.stringValue}>
                  <img src={behance} alt="behance" />
                </a>

                <a className="socialLink" href={data.discord.stringValue}>
                  <img src={discord} alt="discord" />
                </a>
              </div>
            </motion.div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Landing;
