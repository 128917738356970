import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./Drawer.module.css";
function Drawer(props) {
  const drawerState = () => {
    props.navHandler();
  };
  return (
    <div className={`${styles.drawer}`}>
      <div className="Navbar">
        <ul className="nav-items">
          <li className="nav-item">
            <NavLink onClick={drawerState} to="/">
              HOME
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink onClick={drawerState} to="/about">
              ABOUT
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink onClick={drawerState} to="/portfolio">
              PORTFOLIO
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink onClick={drawerState} to="/contact">
              CONTACT
            </NavLink>
          </li>
          <p className="copyRight">&#169; 2023 Omar</p>
        </ul>
      </div>
    </div>
  );
}

export default Drawer;
