import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Card from "../../Components/Card/Card";
import styles from "./About.module.css";
import image from "../../Assets/Image/about.png";
import { Col, Row } from "react-bootstrap";
import { getInfo } from "../../firebase/FirebaseFetch";
import Loading from "../../Components/Loading/Loading";
function About(props) {
  const [data, setData] = useState();
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    getData()
      .then((res) => setData(res))
      .catch((err) => console.log(err));
  }, []);
  const getData = async () => {
    const response = await getInfo().catch((err) => console.log(err));
    setIsloading(false);
    return response;
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <Card>
        <div>
          <motion.h4
            initial={{ y: "-10vh" }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 80, duration: 0.3 }}
            className={styles.title}
          >
            ABOUT ME
          </motion.h4>
          <motion.img
            initial={{ x: "-5vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.4 }}
            className={`${styles.img} img-fluid`}
            src={data.about_image.stringValue}
            alt="about"
          />
          <motion.div
            className={`${styles.section} row`}
            initial={{ x: "20vw" }}
            animate={{ x: 0 }}
            transition={{ type: "spring", duration: 0.4 }}
          >
            <p className={styles.name}>{data.name.stringValue}</p>
            <p className={styles.designation}>{data.designation.stringValue}</p>
          </motion.div>
          <Row className={styles.section}>
            <p className={styles.description}>{data.about_me.stringValue}</p>
          </Row>
          <Row className={styles.section}>
            <Row className={styles.details}>
              <Col>
                <Row className={styles.group}>
                  <Col sm={4}>
                    <span className={styles.label}>Email:</span>
                  </Col>
                  <Col sm={8}>
                    <span className={styles.value}>
                      {data.email.stringValue}
                    </span>
                  </Col>
                </Row>
                <Row className={styles.group}>
                  <Col sm={4}>
                    <span className={styles.label}>Phone:</span>
                  </Col>
                  <Col sm={8}>
                    <span className={styles.value}>
                      {data.phone.stringValue}
                    </span>
                  </Col>
                </Row>
                <Row className={styles.group}>
                  <Col sm={4}>
                    <span className={styles.label}>Nationality:</span>
                  </Col>
                  <Col sm={8}>
                    <span className={styles.value}>
                      {data.nationality.stringValue}
                    </span>
                  </Col>
                </Row>
                <Row className={styles.group}>
                  <Col sm={4}>
                    <span className={styles.label}>Study:</span>
                  </Col>
                  <Col sm={8}>
                    <span className={styles.value}>
                      {data.Study.stringValue}
                    </span>
                  </Col>
                </Row>
                <Row className={styles.group}>
                  <Col sm={4}>
                    <span className={styles.label}>Degree:</span>
                  </Col>
                  <Col sm={8}>
                    <span className={styles.value}>
                      {data.degree.stringValue}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className={styles.group}>
                  <Col sm={4}>
                    <span className={styles.label}>Interest:</span>
                  </Col>
                  <Col sm={8}>
                    <span className={styles.value}>
                      {data.interest.stringValue}
                    </span>
                  </Col>
                </Row>
                <Row className={styles.group}>
                  <Col sm={4}>
                    <span className={styles.label}>Freelance:</span>
                  </Col>
                  <Col sm={8}>
                    <span className={styles.value}>
                      {data.freelance.stringValue}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row>
            <a href={data.cv.stringValue} className={styles.button}>
              DOWNLOAD CV
            </a>
          </Row>
        </div>
      </Card>
    );
  }
}

export default About;
