import { collection, getDocs, addDoc } from "firebase/firestore/lite";
import { db } from "./firebaseConfig";

const infoRef = collection(db, "portfolio");
const showcaseRef = collection(db, "showcase");
const message = collection(db, "message");
export const getInfo = async () => {
  const data = await getDocs(infoRef)
    .then((res) => {
      return res._docs[0]._document.data.value.mapValue.fields;
    })
    .catch((err) => console.log(err));
  return data;
};

export const getShowCase = async () => {
  const data = await getDocs(showcaseRef)
    .then((res) => res._docs)
    .catch((err) => err);
  const cleanData = data.map(
    (data) => data._document.data.value.mapValue.fields
  );
  return cleanData;
};

export const sendMessage = async (data) => {
  try {
    const post = await addDoc(message, data);
    return post;
  } catch (err) {
    console.log(err);
  }
};
