import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { sendMessage } from "../../firebase/FirebaseFetch";
import Card from "../../Components/Card/Card";
import styles from "./Contact.module.css";
function Contact(props) {
  const submitHandler = async (e) => {
    e.preventDefault();
    const updateValue = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      message: e.target.elements.message.value,
    };

    await sendMessage(updateValue);
    e.target.elements.name.value = "";
    e.target.elements.email.value = "";
    e.target.elements.message.value = "";
  };
  return (
    <Card>
      <div>
        <h4 className={`${styles.title}`}>GET IN TOUCH</h4>
        <Row className={`${styles.mapContainer}`}>
          <MapContainer
            center={[23.8782217, 90.3923381]}
            zoom={16}
            scrollWheelZoom={false}
            style={{ width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[23.8782217, 90.3923381]}>
              <Popup>{`test marker`}</Popup>
            </Marker>
          </MapContainer>
        </Row>
        <Row className={`${styles.formSec}`}>
          <form onSubmit={submitHandler} className={`${styles.inputForm}`}>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Name"
              defaultValue=""
              required
            />
            <input
              id="email"
              email="email"
              type="email"
              placeholder="Email"
              defaultValue=""
              required
            />
            <textarea
              id="message"
              message="message"
              type="text"
              placeholder="Message"
              rows={5}
              defaultValue=""
              required
            />
            <Row className={`${styles.btnSec}`}>
              <button type="submit" className={styles.button}>
                SUBMIT
              </button>
            </Row>
          </form>
        </Row>
      </div>
    </Card>
  );
}

export default Contact;
