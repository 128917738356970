import React from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.css";
function Nagivation(props) {
  return (
    <div className="Navbar">
      <ul className="nav-items">
        <li className="nav-item">
          <NavLink to="/">HOME</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/about">ABOUT</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/portfolio">PORTFOLIO</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/contact">CONTACT</NavLink>
        </li>
        <p className="copyRight">&#169; 2023 Omar</p>
      </ul>
    </div>
  );
}

export default Nagivation;
