import React from "react";
import { HashLoader } from "react-spinners";
import styles from "./Loading.module.css";
function Loading(props) {
  return (
    <div className={styles.container}>
      <HashLoader color="#707070" size={50} />
    </div>
  );
}

export default Loading;
