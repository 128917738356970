import React from "react";
import { Container } from "react-bootstrap";
import Landing from "../../Components/Landing/Landing";
import "./Home.css";
function Home({ data }) {
  return (
    <Container fluid={true} className="homeContainer">
      <Landing data={data} />
    </Container>
  );
}

export default Home;
