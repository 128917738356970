import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../../Components/Card/Card";
import styles from "./Portfolio.module.css";
import Loading from "../../Components/Loading/Loading";
import { getShowCase } from "../../firebase/FirebaseFetch";
function Portfolio(props) {
  const [data, setData] = useState();
  const [activeData, setActiveData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState();
  useEffect(() => {
    getData()
      .then((data) => {
        setData(data);
        setActiveData(data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (activeData.length === 0) {
      const renderData = <div className={styles.noData}>No Data</div>;
      setRender(renderData);
    } else {
      const renderData = activeData.map((item, index) => {
        if (item.visible.booleanValue) {
          const data = (
            <div key={index} className={styles.item}>
              <div className={styles.overlay}>
                <h2>{item.title.stringValue}</h2>
                <a
                  className={styles.button}
                  href={item.link.stringValue}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              </div>
              <img
                className={`${styles.img} img-fluid`}
                src={item.image.stringValue}
                alt="Show Case"
              />
            </div>
          );
          return data;
        } else {
          return null;
        }
      });
      setRender(renderData);
    }
  }, [activeData]);
  const getData = async () => {
    setIsLoading(true);
    const data = await getShowCase();
    setIsLoading(false);
    return data;
  };

  const activeHandler = (e) => {
    document
      .getElementById("filter")
      .querySelector(".active")
      .classList.remove("active");
    e.target.classList.add("active");
    if (e.target.id === "all") {
      setActiveData(data);
    } else {
      const filterData = data.filter((item) => {
        return (
          item.category.stringValue === e.target.id &&
          item.visible.booleanValue === true
        );
      });

      setActiveData(filterData);
    }
  };
  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <Card>
        <div className={styles.container}>
          <div className={`${styles.heading} `}>
            <Row>
              <Col>
                <h4 className={`${styles.title} text-center text-sm-start`}>
                  PORTFOLIO
                </h4>
              </Col>
              <Col>
                <div
                  id="filter"
                  className={`${styles.filter} text-center text-sm-end`}
                >
                  <span id="all" className="active" onClick={activeHandler}>
                    All
                  </span>
                  <span id="app" onClick={activeHandler}>
                    App
                  </span>
                  <span id="website" onClick={activeHandler}>
                    Website
                  </span>
                  <span id="image" onClick={activeHandler}>
                    Image
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles.items}>{render}</div>
        </div>
      </Card>
    );
  }
}

export default Portfolio;
