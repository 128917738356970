import { useState } from "react";
import Home from "./Pages/Home/Home";
import Navigation from "./Components/Navigation/Nagivation";
import About from "./Pages/About/About";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Blog from "./Pages/Blog/Blog";
import Contact from "./Pages/Contact/Contact";
import Drawer from "./Components/Drawer/Drawer";
import "./App.css";
import { Route, Routes } from "react-router-dom";
function App() {
  const [drawer, setDrawer] = useState(false);

  const drawerHandler = () => {
    setDrawer(!drawer);
  };

  return (
    <div className="App">
      <div
        className={`navBtn d-block d-md-none`}
        onClick={() => setDrawer(true)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="d-md-none d-block">
        {drawer && (
          <>
            <div className={`overlay`} onClick={() => setDrawer(false)}></div>
            <Drawer navHandler={drawerHandler} />
          </>
        )}
      </div>
      <div className="d-none d-sm-block">
        <Navigation />
      </div>
      <div className="page">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
